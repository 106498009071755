var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("mew-module", {
    staticClass: "d-flex flex-grow-1 pt-6",
    attrs: { title: _vm.title, "has-elevation": true, "has-indicator": true },
    scopedSlots: _vm._u([
      {
        key: "moduleBody",
        fn: function () {
          return [
            _c("v-textarea", {
              staticClass: "VerifyInput",
              attrs: { outlined: "", label: "Signature", value: _vm.message },
              model: {
                value: _vm.message,
                callback: function ($$v) {
                  _vm.message = $$v
                },
                expression: "message",
              },
            }),
            _vm.signResult
              ? _c(
                  "div",
                  {
                    staticClass: "walletBg pa-3 VerifyMessage d-flex flex-wrap",
                  },
                  [
                    _c("span", { staticClass: "signer mr-1" }, [
                      _vm._v(" " + _vm._s(_vm.signer) + " "),
                    ]),
                    _vm.didSign
                      ? _c("span", { staticClass: "font-weight-medium" }, [
                          _vm._v(" did sign the message "),
                        ])
                      : _vm._e(),
                    _vm.didntSign
                      ? _c("span", { staticClass: "font-weight-medium" }, [
                          _vm._v(" didn't sign the message "),
                        ])
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _vm.verificationError
              ? _c("div", { staticClass: "walletBg pa-3" }, [
                  _vm._v(" " + _vm._s(_vm.$t("signMessage.failed")) + " "),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                class: [
                  "text-right",
                  _vm.signResult || _vm.verificationError ? "pt-3" : "",
                ],
              },
              [
                _c("mew-button", {
                  staticClass: "mr-4",
                  attrs: { "btn-style": "light", title: "Clear all" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.clearAll.apply(null, arguments)
                    },
                  },
                }),
                _c("mew-button", {
                  attrs: { disabled: !_vm.message, title: "Verify" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.verifyMessage.apply(null, arguments)
                    },
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }